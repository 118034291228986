input[type="range"] {
  -webkit-appearance: none;
  background: none;
  width: 100%;
  margin: 0;
}

input[type="range"]:focus {
  border: none;
  outline: none;
}

input[type="range"]::-moz-focus-outer {
  border: 0;
}

input[type="range"]::-moz-range-track {
  cursor: pointer;
  box-shadow: none;
  background: #0006;
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 5px;
}

input[type="range"]::-webkit-slider-runnable-track {
  cursor: pointer;
  box-shadow: none;
  background: #0006;
  border: none;
  border-radius: 3px;
  width: 100%;
  height: 5px;
}

input[type="range"]::-ms-track {
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
  width: 100%;
  height: 5px;
}

input[type="range"]::-ms-fill-lower {
  box-shadow: none;
  background: #0006;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-ms-fill-upper {
  box-shadow: none;
  background: #0006;
  border: none;
  border-radius: 3px;
}

.white input[type="range"]::-moz-range-track {
  background: #c8c8c8;
}

.white input[type="range"]::-webkit-slider-runnable-track {
  background: #c8c8c8;
}

.white input[type="range"]::-ms-fill-lower {
  background: #c8c8c8;
}

.white input[type="range"]::-ms-fill-upper {
  background: #c8c8c8;
}

input[type="range"]:focus::-moz-range-track {
  background: #0006;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #0006;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #0006;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #0006;
}

.white input[type="range"]:focus::-moz-range-track {
  background: #c8c8c8;
}

.white input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #c8c8c8;
}

.white input[type="range"]:focus::-ms-fill-lower {
  background: #c8c8c8;
}

.white input[type="range"]:focus::-ms-fill-upper {
  background: #c8c8c8;
}

input[type="range"]::-moz-range-thumb {
  cursor: pointer;
  background: #fff;
  border: .1px solid #00000017;
  border-radius: 8px;
  width: 3px;
  height: 16px;
  box-shadow: 0 0 #000, 0 0 .4px #0d0d0d;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  cursor: pointer;
  background: #fff;
  border: .1px solid #0000004d;
  border-radius: 8px;
  width: 5px;
  height: 16px;
  margin-top: -5.15px;
  box-shadow: 0 0 #000, 0 0 1px #0d0d0d;
}

input[type="range"]::-ms-thumb {
  cursor: pointer;
  background: #fff;
  border: .1px solid #00000017;
  border-radius: 8px;
  width: 3px;
  height: 16px;
  box-shadow: 0 0 #000, 0 0 .4px #0d0d0d;
}

.white input[type="range"]::-moz-range-thumb {
  background: #fff;
  box-shadow: 0 0 4px 1px #00000080;
}

.white input[type="range"]::-webkit-slider-thumb {
  background: #fff;
  box-shadow: 0 0 4px 1px #00000080;
}

.white input[type="range"]::-ms-thumb {
  background: #fff;
  box-shadow: 0 0 4px 1px #00000080;
}

.black input[type="range"]::-moz-range-thumb {
  background: #333;
  box-shadow: 0 0 2px 1px #c8c8c84d;
}

.black input[type="range"]::-webkit-slider-thumb {
  background: #333;
  box-shadow: 0 0 2px 1px #c8c8c84d;
}

.black input[type="range"]::-ms-thumb {
  background: #333;
  box-shadow: 0 0 2px 1px #c8c8c84d;
}
/*# sourceMappingURL=index.624e95f1.css.map */
