/**
 * Based on a stylesheet automatically generated from http://danielstern.ca/range.css/ (2014)
 */

/**
 * Make it almost invisible
 */
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
  border: none;
}
input[type="range"]::-moz-focus-outer {
  /* From https://stackoverflow.com/questions/18794026/remove-dotted-outline-from-range-input-element-in-firefox */
  border: 0;
}

/**
 * Slider track
 */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  border: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  border: none;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  border: none;
}
input[type="range"]::-ms-fill-upper {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  border: none;
}

/* White */
.white input[type="range"]::-moz-range-track {
  background: #c8c8c8;
}
.white input[type="range"]::-webkit-slider-runnable-track {
  background: #c8c8c8;
}
.white input[type="range"]::-ms-fill-lower {
  background: #c8c8c8;
}
.white input[type="range"]::-ms-fill-upper {
  background: #c8c8c8;
}

/**
 * Slider track on focus
 */
input[type="range"]:focus::-moz-range-track {
  background: rgba(0, 0, 0, 0.4);
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: rgba(0, 0, 0, 0.4);
}
input[type="range"]:focus::-ms-fill-lower {
  background: rgba(0, 0, 0, 0.4);
}
input[type="range"]:focus::-ms-fill-upper {
  background: rgba(0, 0, 0, 0.4);
}

/* White */
.white input[type="range"]:focus::-moz-range-track {
  background: #c8c8c8;
}
.white input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #c8c8c8;
}
.white input[type="range"]:focus::-ms-fill-lower {
  background: #c8c8c8;
}
.white input[type="range"]:focus::-ms-fill-upper {
  background: #c8c8c8;
}

/**
 * Slider thumb
 */
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0.4px #0d0d0d;
  border: 0.1px solid rgba(0, 0, 0, 0.09);
  height: 16px;
  width: 3px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  box-shadow: 0px 0px 0px #000000, 0px 0px 1px #0d0d0d;
  border: 0.1px solid rgba(0, 0, 0, 0.09);
  border: 0.1px solid rgba(0, 0, 0, 0.3);
  height: 16px;
  width: 5px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  margin-top: -5.15px;
}
input[type="range"]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000, 0px 0px 0.4px #0d0d0d;
  border: 0.1px solid rgba(0, 0, 0, 0.09);
  height: 16px;
  width: 3px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
  /* Is this right? TODO
  height: 5.7px;
  */
}

/* White */
.white input[type="range"]::-moz-range-thumb {
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
}
.white input[type="range"]::-webkit-slider-thumb {
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
}
.white input[type="range"]::-ms-thumb {
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.5);
}

/* Black */
.black input[type="range"]::-moz-range-thumb {
  background: #333;
  box-shadow: 0px 0px 2px 1px rgba(200, 200, 200, 0.3);
}
.black input[type="range"]::-webkit-slider-thumb {
  background: #333;
  box-shadow: 0px 0px 2px 1px rgba(200, 200, 200, 0.3);
}
.black input[type="range"]::-ms-thumb {
  background: #333;
  box-shadow: 0px 0px 2px 1px rgba(200, 200, 200, 0.3);
}
